<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" :style="{ width: '80px' }"></th>
        <th scope="col">
          <div class="text-truncate">{{ $t('eventMapModal.differenceModal.details.asPer') }} {{ businessName }}</div>
        </th>
        <th scope="col">
          <div class="text-truncate">{{ $t('eventMapModal.differenceModal.details.asPer') }} {{ supplierName }}</div>
        </th>
        <th scope="col" :style="{ width: '100px' }" class="difference">
          {{ $t('eventMapModal.differenceModal.details.difference') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="termKey in diffsToShow" :key="termKey">
        <th scope="row" class="text-muted fw-normal">{{ $t(`commons.${termKey}`) }}</th>
        <td>
          <el-tooltip
            :disabled="
              !['price', 'discount'].includes(termKey) || !customerProduct.source || !customerProduct.source[termKey]
            "
            placement="top"
          >
            <template v-if="customerProduct.source && customerProduct.source[termKey]" #content>
              <div v-if="customerProduct.source[termKey].type === 'user'">
                {{
                  `${$t('commons.source')}: ${$t(
                    'eventMapModal.orderCard.differenceModal.source.updatedManuallyBy'
                  )} ${displayUserFullName(customerProduct.source[termKey].user)}`
                }}
              </div>
              <div v-else-if="customerProduct.source[termKey].type === 'term'">
                {{
                  `${$t('commons.source')}: ${$t('eventMapModal.orderCard.differenceModal.source.accordingToTerm')} - `
                }}
                <span v-if="customerProduct.source[termKey].term.type === 'PROMOTION'">
                  {{ `${formatPercent(customerProduct.source[termKey].term.rewardValue)} ${$t('commons.discount')}` }}
                </span>
                <span v-else-if="customerProduct.source[termKey].term.type === 'PRICE_LIST'">
                  {{ `${$t('priceList')}: ${priceList.name}` }}
                </span>
                <span v-else-if="customerProduct.source[termKey].term.pricingMethod === 'FIXED_PRICE'">
                  {{
                    !isNil(customerProduct.source[termKey].term.price)
                      ? `${formatMoney(customerProduct.source[termKey].term.price)} ${$t('fixedPrice')}`
                      : $t('commons.unknown')
                  }}
                </span>
                <span v-else-if="customerProduct.source[termKey].term.pricingMethod === 'PRICE_INDEX'">
                  {{
                    `${customerProduct.source[termKey].term.priceIndexName} ${$t('commons.fromDate')} ${
                      customerProduct.source[termKey].additionalInfo &&
                      formatDate(customerProduct.source[termKey].additionalInfo.priceListDate)
                    }`
                  }}
                </span>
              </div>
              <div v-else>
                {{
                  `${$t('commons.source')}: ${$t('commons.accordingTo')} ${$t(
                    `document.exports.schema.type.shortName.${customerProduct.source[termKey].document.type}`
                  )} ${customerProduct.source[termKey].document.documentNumber}`
                }}
              </div>
            </template>
            <div
              :class="{
                underline:
                  ['price', 'discount'].includes(termKey) && customerProduct.source && customerProduct.source[termKey],
              }"
            >
              {{ customerProduct[termKey] }}
            </div>
          </el-tooltip>
        </td>
        <td>{{ supplierProduct[termKey] }}</td>
        <td class="difference text-warning">{{ productsDifference[termKey] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { isNil } from 'ramda';
import { computed, getCurrentInstance } from 'vue';

import { percent } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { usePriceList } from '@/modules/price/compositions';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  props: {
    customerOrderItem: { type: Object, required: true },
    supplierName: { type: String, required: true },
    businessName: { type: String, required: true },
    difference: { type: Object, required: true },
    diffsToShow: { type: Array, required: true },
    supplierOrderItem: { type: Object, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    const formatMoney = (number) => formatCentsToCurrency(number) ?? '';
    const formatMoneyShekels = (number) => formatToCurrency(number) ?? '';
    const formatPercent = (number) =>
      typeof number === 'number' && !Number.isNaN(number)
        ? Number(number / 100).toLocaleString(root.$i18n.locale, percent)
        : '';
    const formatQuantity = (number) =>
      typeof number === 'number' && !Number.isNaN(number) ? Number(number).toLocaleString(root.$i18n.locale) : '';
    const formatDate = (date) => new Date(date).toLocaleDateString(root.$i18n.locale, options.short);

    const customerProduct = computed(() => ({
      quantity: formatQuantity(props.customerOrderItem.quantity ?? 0),
      price: !isNil(props.customerOrderItem.price)
        ? formatMoneyShekels(props.customerOrderItem.price)
        : props.difference.customerItemId
        ? root.$t('commons.unknown')
        : '-',
      discount: !isNil(props.customerOrderItem.discount)
        ? formatPercent(props.customerOrderItem.discount)
        : props.difference.customerItemId
        ? ''
        : '-',
      net: !isNil(props.customerOrderItem.netPrice)
        ? formatMoneyShekels(props.customerOrderItem.netPrice)
        : props.difference.customerItemId
        ? root.$t('commons.unknown')
        : '-',
      total: !isNil(props.customerOrderItem.totalAmount)
        ? formatMoneyShekels(props.customerOrderItem.totalAmount)
        : props.difference.customerItemId
        ? root.$t('commons.unknown')
        : '-',
      source: props.customerOrderItem.source,
    }));

    const displayUserFullName = (user) => {
      if (!user) return '-';
      const { firstName, lastName } = user.profile;
      return `${firstName} ${lastName}`;
    };

    const { priceList } = usePriceList(computed(() => props.customerOrderItem.source?.term?.priceListId));

    const getSupplierQuantity = () => {
      if (!isNil(props.supplierOrderItem)) return formatQuantity(props.supplierOrderItem.quantity);
      return props.difference.supplierItemId ? root.$t('commons.unknown') : '-';
    };

    const getSupplierPrice = () => {
      if (!isNil(props.supplierOrderItem)) return formatMoneyShekels(props.supplierOrderItem.price);
      return props.difference.supplierItemId ? root.$t('commons.unknown') : '-';
    };

    const getSupplierDiscount = () => {
      if (!isNil(props.supplierOrderItem)) return formatPercent(props.supplierOrderItem.discount);
      return props.difference.supplierItemId ? '' : '-';
    };

    const getSupplierNetAmount = () => {
      if (!isNil(props.supplierOrderItem)) return formatMoneyShekels(props.supplierOrderItem.netPrice);
      return props.difference.supplierItemId ? root.$t('commons.unknown') : '-';
    };

    const getSupplierTotal = () => {
      if (!isNil(props.supplierOrderItem)) return formatMoneyShekels(props.supplierOrderItem.totalAmount);
      return props.difference.supplierItemId ? root.$t('commons.unknown') : '-';
    };

    const supplierProduct = computed(() => ({
      quantity: getSupplierQuantity(),
      price: getSupplierPrice(),
      discount: getSupplierDiscount(),
      net: getSupplierNetAmount(),
      total: getSupplierTotal(),
    }));

    const productsDifference = computed(() => {
      return {
        price: props.difference.price
          ? formatMoneyShekels(props.difference.price.supplierValue - props.difference.price.customerValue)
          : '',
        quantity: props.difference.quantity
          ? formatQuantity(props.difference.quantity.supplierValue - props.difference.quantity.customerValue)
          : '',
        discount: props.difference.discount
          ? formatPercent((props.difference.discount.supplierValue - props.difference.discount.customerValue) * -1)
          : '',
        net: props.difference.netPrice
          ? formatMoneyShekels(props.difference.netPrice.supplierValue - props.difference.netPrice.customerValue)
          : '',
        total: props.difference.amount ? formatMoneyShekels(props.difference.amount) : root.$t('commons.unknown'),
      };
    });

    const checkDisabledTooltip = (termKey) => {
      if (customerProduct.source) {
        return !['price', 'discount'].includes(termKey) || !customerProduct.source[termKey];
      }
      return false;
    };

    return {
      customerProduct,
      supplierProduct,
      productsDifference,
      displayUserFullName,
      formatMoney,
      formatMoneyShekels,
      formatPercent,
      formatDate,
      priceList,
      checkDisabledTooltip,
      isNil,
    };
  },
};
</script>

<style scoped lang="scss">
.underline {
  display: flex;
  flex-direction: column;
  width: fit-content;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed;
  }
}

.table {
  table-layout: fixed;

  th,
  td {
    padding: 5px;
    &.difference {
      text-align: end;
    }
  }
}
</style>
