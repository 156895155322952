<template>
  <div>
    <div class="product-container modal-container">
      <span class="details">
        <span class="icon-container">
          <InformationIcon width="24px" height="24px" />
        </span>
        <span>
          <div class="name fw-bold">{{ customerOrderItem.product.name }}</div>
          <div :style="{ visibility: customerOrderItem.product.sku ? 'visible' : 'hidden' }" class="sku">
            {{ $t('commons.sku') }} {{ customerOrderItem.product.sku }}
          </div>
        </span>
      </span>
      <span class="buttons-container">
        <Button class="p-1" type="secondary" @click.prevent.stop="viewDoc = true">
          <el-tooltip
            class="item"
            effect="dark"
            :content="`${$t(`document.exports.schema.type.shortName.${document.type}`)} ${document.documentNumber}`"
            placement="top"
          >
            <DocumentIcon />
          </el-tooltip>
        </Button>
        <Button class="p-1" type="secondary" :disabled="!agreementIdRelevantToTerm" @click="agreementModalOpen = true">
          <el-tooltip
            class="item"
            effect="dark"
            :disabled="!agreementTooltipContent"
            :content="agreementTooltipContent"
            placement="top"
          >
            <HandshakeIcon />
          </el-tooltip>
        </Button>

        <Button class="p-1" type="secondary" @click="openProductModal(customerOrderItem.product.id)">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('eventMapModal.differenceModal.tooltip.product')"
            placement="top"
          >
            <InformationIcon />
          </el-tooltip>
        </Button>
      </span>
    </div>
    <div class="difference-container modal-container">
      <div class="container-header fw-bold">{{ $t('eventMapModal.differenceModal.details.title') }}</div>
      <DifferenceTable
        :customer-order-item="customerOrderItem"
        :diffs-to-show="diffsToShow"
        :difference="difference"
        :supplier-name="supplier.name"
        :business-name="business.name"
        :supplier-order-item="supplierOrderItem"
      />
    </div>

    <div v-if="isAdmin" class="resolution-container modal-container">
      <div class="container-header fw-bold">{{ $t('eventMapModal.differenceModal.decision.title') }}</div>
      <slot />
    </div>

    <DocumentModal v-if="viewDoc" :visible.sync="viewDoc" :document-id="document.id" />
    <AgreementModal
      v-if="agreementModalOpen"
      :agreement-ids="[agreementIdRelevantToTerm]"
      @close="agreementModalOpen = false"
    />
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { InformationIcon, HandshakeIcon, DocumentIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import DocumentModal from '@/modules/documentModal/DocumentModal';
import { openProductModal } from '@/modules/products/store/product-modal';
import { AGREEMENTS_QUERY } from '@/modules/eventMapModal/compositions/order/graphql';
import AgreementModal from '@/modules/purchase-management/components/agreements/AgreementModal';
import { options } from '@/locale/dateConfig';
import { useUser } from '@/modules/auth';

import DifferenceTable from './DifferenceTable';

export default {
  components: {
    AgreementModal,
    DifferenceTable,
    HandshakeIcon,
    DocumentIcon,
    InformationIcon,
    Button,
    DocumentModal,
  },
  props: {
    customerOrderItem: { type: Object, required: true },
    difference: { type: Object, required: true },
    diffsToShow: { type: Array, required: true },
    document: { type: Object, required: true },
    supplier: { type: Object, required: true },
    business: { type: Object, required: true },
    date: { type: Date, required: true },
    supplierOrderItem: { type: Object, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { isAdmin } = useUser();
    const formatDate = (date) =>
      !isNil(date) ? new Date(date).toLocaleDateString(root.$i18n.locale, options.short) : null;

    const { result: agreementsResult } = useQuery(AGREEMENTS_QUERY, () => ({
      supplierId: props.supplier?.id,
      businessId: props.business.id,
      activeAt: props.date,
    }));
    const agreements = useResult(agreementsResult, []);
    const agreementIdRelevantToTerm = computed(
      () =>
        agreements.value.find((agreement) =>
          agreement.termIds.includes(props.customerOrderItem.source?.price?.term?.id)
        )?.id
    );

    const agreementTooltipContent = computed(() => {
      if (agreementIdRelevantToTerm.value) {
        return root.$i18n.t('eventMapModal.differenceModal.tooltip.agreement');
      }

      if (props.customerOrderItem.source?.price?.term) {
        return root.$i18n.t('eventMapModal.differenceModal.tooltip.noTermRecord', {
          date: formatDate(props.customerOrderItem.source?.price?.term?.updatedAt),
        });
      }

      return '';
    });

    return {
      isAdmin,
      viewDoc: ref(false),
      agreementIdRelevantToTerm,
      agreementTooltipContent,
      agreementModalOpen: ref(false),
      openProductModal,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.modal-container {
  border: 1px solid $outline;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px;
}

.container-header {
  font-size: 1em;
  color: black;
}

.product-container {
  color: $typography-primary;
  display: flex;
  justify-content: space-between;
  border-color: $informative;

  background: #f7f9fd;

  .details {
    display: flex;
    align-items: center;

    .icon-container {
      display: flex;
      align-items: flex-start;
      height: 100%;
      color: $informative;

      [dir='rtl'] & {
        margin-left: 5.5px;
      }

      [dir='ltr'] & {
        margin-right: 5.5px;
      }
    }

    .name {
      font-size: 1.2em;
      line-height: 1;
    }

    .sku {
      font-weight: 400;
      font-size: 0.75em;
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;
  }
}

.difference-container {
  font-size: 1rem;
  .container-header {
    margin-bottom: 1.75em;
  }
}

.resolution-container {
  width: 100%;
}

.status-container {
  height: 87px;
}
</style>
